import { createGlobalStyle } from 'styled-components';
//import bg from '../assets/images/bg.svg';
import arrow_up from '../assets/images/arrow_up.svg'
import hand_rawn_separator_1 from '../assets/images/hand-drawn-separator-1.svg'
import hand_rawn_separator_2 from '../assets/images/hand-drawn-separator-2.svg'

const GlobalStyles = createGlobalStyle`
  :root {
    
    // Esquema 19
    /*
    --color_0: #EAE7DC;
    --color_1: #D8C3A5;
    --color_2: #8E8D8A;
    --color_3: #E98074;
    --color_4: #E85A4F;
    
    // Esquema 22
    --color_0: #88BDBC;
    --color_1: #254E58;
    --color_2: #112D32;
    --color_3: #4F4A41;
    --color_4: #6E6658;

    // Esquema 25
    --color_0: #F8E9A1;
    --color_1: #F76C6C;
    --color_2: #A8D0E6;
    --color_3: #374785;
    --color_4: #24305E;

    // Esquema 26
    --color_0: #A64AC9;
    --color_1: #FCCD04;
    --color_2: #FFB48F;
    --color_3: #F5E6CC;
    --color_4: #17E9E0;

    // Esquema 37
    --color_0: #FBE8A6;
    --color_1: #F4976C;
    --color_2: #303C6C;
    --color_3: #B4DFE5;
    --color_4: #D2FDFF;

    // Esquema 39
    --color_0: #F78888;
    --color_1: #F3D250;
    --color_2: #ECECEC;
    --color_3: #90CCF4;
    --color_4: #5DA2D5;

    // Esquema 40
    --color_0: #E8EFF1;
    --color_1: #CCC6C6;
    --color_2: #FADCD6;
    --color_3: #DCCCC0;
    --color_4: #94BDA3;

    Facebook backup: #1976D2;
    Whatsapp backup: #7AD06D;
    
    */

    // Esquema activo
    --color_0: #E8EFF1;
    --color_1: #CCC6C6;
    --color_2: #FADCD6;
    --color_3: #DCCCC0;
    --color_4: #94BDA3;
    --color_5: #fff2fa;
    --color_6: #ffe0f4;
    --color_7: #e69ccc;
    --color_8: #fafafa;

    --color_negro_1: #262626;
    --color_negro_2: #4a4a4a;
  }

  html {
    font-size: 8px;
    //background-color: #ffffff;

    scroll-behavior: smooth;

    @media (prefers-reduced-motion: reduce) {
      html {
          scroll-behavior: auto;
      }
    }
  }

  body {
    font-size: 2rem;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--color_2);
    color: var(--color_0);
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--color_2);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--color_7) var(--color_6);
  }
  body::-webkit-scrollbar-track {
    background: whitesmoke;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--color_6);
    border-radius: 6px;
    border: 3px solid whitesmoke;
  }

  hr {
    border: 0;
    height: 8px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .divider
  {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 4rem;
    height: 1px;
  }

  .div-transparent:before
  {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, var(--color_7), transparent);
  }

  .hand-drawn-divider-1 {
    //content: "";
    //position: absolute;
    //top: 0;
    //left: 5%;
    //right: 5%;
    display: block;
    width: 100%;
    height: 30px;
    margin-top: 1rem;
    background-image: url(${hand_rawn_separator_1});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (max-width: 800px) {
      height: 25px;
    }
  }

  .hand-drawn-divider-2 {
    //content: "";
    //position: absolute;
    //top: 0;
    //left: 5%;
    //right: 5%;
    display: block;
    width: 100%;
    height: 20px;
    margin-top: 1rem;
    background-image: url(${hand_rawn_separator_2});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (max-width: 800px) {
      height: 15px;
    }
  }

/*
.div-arrow-down:after
{
	content: "";
	position: absolute;
	z-index: 1;
	top: -7px;
	left: calc(50% - 7px);
	width: 14px;
	height: 14px;
	transform: rotate(45deg);
	background-color: white;
	border-bottom: 1px solid var(--color_7);
	border-right: 1px solid var(--color_7);
}
*/
::selection {
  color: white;
  background: var(--color_4);
}

.sidebar-btns {
  display: none;
  position: fixed;
  bottom: 70px;
  right: 5%;
  z-index: 99;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 1rem;
  border-radius: 50%;
  background-image: url(${arrow_up});
  background-repeat: no-repeat;
  background-size: 60%;
  width: 50px;
  height: 50px;
  background-position: center;
  transition: 0.3s;
  opacity: 70%;
}

#scroll-btn {
  //right: 15%;
  background-image: url(${arrow_up});
  background-color: var(--color_6);
}
`;

export default GlobalStyles;