import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import loupe from '../assets/images/loupe.svg';

const NavStyles = styled.nav`
  //margin-bottom: 3rem;
  display: grid;
  justify-content: center;
  background-color: var(--color_6);
  max-width:100%;
  height: 6rem;

  .menu-ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    width: 70%;
    display: grid;
    //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: auto auto auto auto;

    grid-template-rows: auto;
    gap: 0;

    //margin-top: -6rem;

    justify-items: center;
  }

  a, button {
    margin: 0;
    padding: 0;
    justify-self: center;
    box-shadow: none;
    text-shadow: none;
    outline:none;
    color: black;
    font-size: 2.5rem;
    font-weight: 500;
    text-decoration: none;
    display: block;
    width: 15rem;
    background-color: transparent;
    line-height: 6rem;
    -webkit-tap-highlight-color: var(--color_7);
    //padding-top: 1rem;
    //padding-bottom: 1rem;
    //border-radius: 3px;

    &:hover {
      //font-weight: 600;
      background-color: var(--color_7);
      //color: white;
    }
    img {
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }

  //background: var(--color_2);

  /* Dropdown Button */
  /*
  .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  */
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--color_6);
    //min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    z-index: 3;
    line-height: 3rem;
    text-align: left;
    padding-left: 1rem;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: var(--color_7);}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: var(--color_7);}
  /*
  img {
    padding-bottom: 1.5rem;
    padding-right: 4px;
  }
  */
  .contenedor-lupa-texto {
    display: grid;
    grid-template-columns: 20px auto;
    justify-items: left;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 800px) {

    .menu-ul {
      width: 100%;
      grid-gap: 0;
    }

    a, button {
      width: 9.5rem;
      font-size: 2rem;
    }

    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      padding-left: 0.5rem;
      width: 10rem;
      text-decoration: none;
      display: block;
      z-index: 3;
      line-height: 3rem;
      text-align: left;
      padding-left: 1rem;
    }
    /*
    img {
      padding-bottom: 1.2rem;
      padding-right: 4px;
    }
    */
  }

`;

/*
export default function Nav() {
  return (
    <NavStyles>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/articulos">Artículos</Link>
        </li>
        <li>
          <Link to="/bio">Bio</Link>
        </li>
        <li>
          <a href="https://www.facebook.com/psicologa.en.flores.laura"><img src={facebook} align="center" alt="Facebook" height="20px"/></a>
        </li>
        <li>
          <a href="https://wa.me/5491134351210"><img src={whatsapp} align="center" alt="Whatsapp" height="20px"/></a>
        </li>
      </ul>
    </NavStyles>
  );
}
*/

/*
export default function Nav() {
  return (
    <NavStyles>
      <ul className="menu-ul">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/articulos">Artículos</Link>
        </li>
        <li>
          <Link to="/bio">Bio</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
*/

export default function Nav({ categorias } ) {
  return (
    <NavStyles>
      <ul className="menu-ul">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="dropdown">
          <button className="dropbtn">Artículos</button>
            <div className="dropdown-content">
              <Link className="extra-links" key="key_Reflexiones_cat" to={`/articulos/Reflexiones/1`}>
                  Reflexiones
              </Link>
              {categorias.map(categoria => {
                if (categoria.title !== "Reflexiones") {
                  return(
                    <Link className="extra-links" key={`key_${categoria.title}_cat`} to={`/articulos/${categoria.title}/1`}>
                      {categoria.title}
                    </Link>
                  )
                }
              })}
              <Link className="extra-links" key="key_Todos_cat" to={`/articulos/Todos`}>
                <div className="contenedor-lupa-texto">
                  <img src={loupe} align="center" alt="buscar" height="12px"/>
                  <div>Buscar</div>
                </div>
              </Link>

            </div>
        </li>
        <li>
          <Link to="/bio">Bio</Link>
        </li>
        <li>
          <Link to="/contacto">Contacto</Link>
        </li>
      </ul>
    </NavStyles>
  );
}