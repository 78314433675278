import React from 'react';
import styled from 'styled-components';
import phone_call from '../assets/images/phone-call.svg';
import email from '../assets/images/email.svg';
import location from '../assets/images/location.svg';

const FooterStyles = styled.footer`
  text-align: center;
  background-color: var(--color_0);

  .footer-grid {
    padding-top: 3rem;
    width: 80rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
  }

  .made-by {
    margin-bottom: 0;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .contenedor-dato-contacto {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 1rem;
  }

  @media (max-width: 800px) {
    .footer-grid {
      width: 90%;
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      gap: 0;
    }

    .contenedor-dato-contacto {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1.5rem;

      .link-icono {
        justify-self: right;
        align-self: center;
        padding-bottom: 8px;
      }
      .texto-de-icono {
        justify-self: left;
        align-self: center;
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div className="footer-grid">
        <div className="contenedor-dato-contacto">
          <a className="link-icono" href="mailto:laura.milberg@gmail.com"><img src={email} align="center" alt="email" height="20px"/></a>
          <div className="texto-de-icono">lauramilberg@gmail.com</div>
        </div>
        <div className="contenedor-dato-contacto">
          <a className="link-icono" href="tel:11-3435-1210"><img src={phone_call} align="center" alt="teléfono" height="20px"/></a>
          <div className="texto-de-icono">11-3435-1210</div>
        </div>
        <div className="contenedor-dato-contacto">
          <a className="link-icono" href="https://goo.gl/maps/rhnPVHNX6cYWvGzr9"><img src={location} align="center" alt="dirección" height="20px"/></a>
          <div className="texto-de-icono">Ramón Falcón 1900, CABA</div>
        </div>
      </div>
      <p className="made-by">
        &copy; Laura Milberg {new Date().getFullYear()}
        <br/>
        Diseñado por <span style={{fontWeight: "bold", color: "dimgrey"}}>Sebastián Alcántara</span>
      </p>
    </FooterStyles>
  );
}